<template>
  <div class="page">
    <div class="top">编辑日报</div>
    <el-input class="mid-input" resize="none" type="textarea" v-model="log" />
    <div class="down">
        <el-button class="save-btn" :loading="buttonLoading" @click="saveLog">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            buttonLoading: false,
            calendarDate: '',
            log: '',
        }
    },
    created() {
        console.log('路由参数',this.$route.query);
        this.calendarDate = this.$route.query.date
        this.$nextTick(() => {
            this.getLog()
        })
    },
    methods: {
        getLog() {
            this.$http({
                url: this.$http.adornUrl('/wxapp/work/log/getLog'),
                method: 'get',
                params: this.$http.adornParams({
                    day: this.calendarDate
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.log = data.log
                }
            })
        },
        saveLog() {
            this.buttonLoading = true
            this.$http({
                url: this.$http.adornUrl('/wxapp/work/log/save'),
                method: 'GET',
                params: this.$http.adornParams({
                    day: this.calendarDate,
                    log: this.log,
                })
            }).then(({data}) => {
                if(data && data.code === 0) {
                    this.$toast.success({
                        message: '保存成功',
                        duration: 1500,
                    })
                    setTimeout(() => {
                        this.buttonLoading = false
                        this.$router.go(-1)
                    },1500)
                }else {
                    this.buttonLoading = false
                    this.$toast.fail(data.msg)
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page {
    width: 100%;
    min-height: 100vh;
    background: #FFF;
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    .top {
        height: 96px;
        border-bottom: 2px solid #EEEEEE;
        line-height: 96px;
        font-size: 32px;
        font-family: 'PingFang Bold';
        font-weight: 600;
        color: #333333;
    }
    .min-input {
        height: calc(100vh - 128px - 96px);
    }
    .down {
        padding-bottom: 40px;
        .save-btn {
            width: 100%;
            height: 88px;
            background: #4581F8;
            border-radius: 8px;
            font-size: 32px;
            font-family: 'PingFang Bold';
            font-weight: 500;
            color: #FFFFFF;
        }
    }
}
</style>
<style scoped>
.mid-input >>> .el-textarea__inner {
    padding: 20px 0;
    border: none;
    height: calc(100vh - 128px - 96px);
}
</style>